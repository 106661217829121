<template>
  <div id="partners">
    <b-container>
      <b-col>
        <b-row class="img-title">
          <b-col sm="4" md="2" class="" v-for="(item, index) in imageHeader" :key="index">
            <div class="header-img-list" :style="{'background-image':'url('+item.link+')'}"></div>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="4">
            <span class="prizes">Partners</span><br>
            <span class="hastagfeel">#FeelMongolia</span>
          </b-col>
          <b-col sm="12" md="8">
            <span class="the-feel">The #Feel Mongolia Campaign is organizer by </span>
            <br><br><br>
            <img :src="metLogo" alt="metLogo" width="100%" class="met-logo">
            <br><br><br>
            <span class="partners">Partners</span>
            <br><br><br>
            <b-row class="list-partners">
              <ImagesBoard />
              <!-- <b-col sm="6" md="4" class="box-partners">
                <div class="img-partners"></div>
              </b-col> -->
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-container>
  </div>
</template>

<script>
import img01 from '@/assets/img/campaign/img01.jpg';
import img02 from '@/assets/img/campaign/img02.jpg';
import img03 from '@/assets/img/campaign/img03.jpg';
import img04 from '@/assets/img/campaign/img04.jpg';
import img05 from '@/assets/img/campaign/img05.jpg';
import img06 from '@/assets/img/campaign/img06.jpg';
import metLogo from '@/assets/img/campaign/MET_logo_blue.png';
import ImagesBoard from "@/components/ImagesBoard";
export default {
  name: 'partners',
  data() {
    return {
      imageHeader : [
        { link : img01 },
        { link : img02 },
        { link : img03 },
        { link : img04 },
        { link : img05 },
        { link : img06 }
      ],
      metLogo : metLogo
    }
  },
  components: {
    ImagesBoard
  },
}
</script>

<style lang="scss" scoped>
  @media (min-width: 1200px) {
    .container {
      max-width: 1240px;
    }
  }

  #partners {
    padding-top: 230px;

    .img-title {
      padding-bottom: 200px;
      .header-img-list {
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        padding-bottom: 100%;
        margin-bottom: 10px;
      }
    }

    .prizes {
      font-weight: bold;
      font-size: 16px;
    }

    .hastagfeel {
      font-weight: bold;
      font-size: 25px;
    }

    @media (min-width: 1024px) {
      .prizes {
        font-weight: bold;
        font-size: 25px;
      }

      .hastagfeel {
        font-weight: bold;
        font-size: 36px;
      }
    }
    
    @media (min-width: 1200px) {
      .prizes {
        font-weight: bold;
        font-size: 25px;
      }

      .hastagfeel {
        font-weight: bold;
        font-size: 48px;
      }
    }

    .the-feel, .partners {
      font-size: 25px;
      font-weight: bold;
    }

    .list-partners {
      margin: 0 -30px;

      .box-partners {
        padding: 30px;
        .img-partners {
          width: 100%;
          padding-bottom: 100%;
          background-color: #DDD;
        }
      }
      
    }

    
    
  }
</style>


